.wrap-banners {
    margin: 0 !important;
    background: linear-gradient(to bottom, #0C0C60 43.23%, #0c0d60 100%);

    @include media-breakpoint-up(md) {
        padding-bottom: 220px;
    }
    @include media-breakpoint-up(lg) {
        padding-bottom: 0;
    }

    .item {
        position: relative;

        picture {
            position: relative;
            display: block;
        }

        video {
            width: 100%;
        }
    }

    .container {
        position: absolute;
        left: 50%;
        top: 25%;
        transform: translate(-50%, -25%);
        z-index: 1;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(sm) {
            top: 30vw;
        }
        @include media-breakpoint-up(md) {
            top: 25%;
            transform: translate(-50%, -25%);
        }

        .main-content {
            margin: 0 auto;
            width: 90%;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(sm) {
                width: 400px;
            }
            @include media-breakpoint-up(md) {
                margin: 0;
                width: auto;
            }

            .banner-img {
                margin: 0 auto;
                display: block;
                width: 275px;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(sm) {
                    width: 322px;
                }
                @include media-breakpoint-up(md) {
                    width: 380px;
                }
                @include media-breakpoint-up(lg) {
                    width: 480px;
                }

                img, svg {
                    width: 100%;
                }
            }

            h1 {
                span {
                    display: none;
                }
            }

            label, h1 {
                color: #fff;
                display: block;
                font-size: 18px;
                font-weight: 300;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
                margin-top: -12px;
                letter-spacing: 2px;
                width: 100%;
                transition: all 0.5s ease-out;

                &.subtitle {
                    display: block;
                    font-weight: 500;
                    font-size: 18px;
                    width: 100%;

                    @include media-breakpoint-up(xl) {
                        font-size: 20px;
                    }
                }

                &.title {
                    display: block;
                    color: #FF0178;
                    font-size: 27px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    text-transform: uppercase;
                    text-align: center;
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        font-size: 45px;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 50px;
                    }
                }
            }

            .Btn {
                background-image: linear-gradient(to top, #d02e7c, #d02e7c);
                color: #fff;
                display: block;
                padding: 13px 37px 13px 41px;
                border-radius: 20px;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                margin: 40px auto 0;
                width: 187px;
                height: 42px;
                text-transform: uppercase;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    margin: 40px auto 40px;
                }
            }
        }

    }

    .slick-dots {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        bottom: 3%;
        width: 200px;

        @include media-breakpoint-up(md) {
            left: 16%;
            bottom: 5%;
        }

        @media (min-width: 1600px) {
            left: 20%;
            bottom: 6%;
        }

        li {
            margin: 0 10px;

            &.slick-active {
                button {
                    &:before {
                        color: #cb2585;
                        opacity: 1;
                    }
                }
            }

            button {
                &:before {
                    color: white;
                    opacity: 1;
                    font-size: 16px;
                }
            }
        }
    }

    .line {
        background: linear-gradient(to right, #d02e7c, #71549a, #1298cf);
        position: relative;
        margin: 46px auto 50px;
        height: 1px;
        display: block;
        width: 80%;

        @include media-breakpoint-up(md) {
            margin: 60px auto 55px;
            width: 600px;
        }
    }

    .wrap-app-store {
        margin: 0 auto;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 300px;
        bottom: -60px;

        @include media-breakpoint-up(md) {
            bottom: 0;
        }
    }
}

.wrap-main-subscribe {
    background-color: #0D0D50;
    background-image: url("/images/inzpira-background.png");
    background-size: 150% auto;
    background-position: center 88%;
    background-repeat: no-repeat;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(md) {
        background-size: 100% auto;
        background-position: center 90%;
    }

    @include media-breakpoint-up(xl) {
        background-position: center 100%;
    }

    @media (min-width: 1440px) {
        background-size: 1432px auto;
        background-position: center 110%;
    }

    .wrap-subscribe {
        background: linear-gradient(to bottom, #E41C7A 0%, #0C0C60 43.23%, #0D0D50 100%);
        padding-bottom: 50px;
        padding-top: 70px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            background: linear-gradient(to bottom, #E41C7A 30%, #0C0C60 90%, #0D0D50 100%);
            padding-bottom: 70px;
            padding-top: 70px;
        }
        @include media-breakpoint-up(lg) {
            padding-bottom: 80px;
            padding-top: 80px;
        }

        .wrap-features {
            margin: 0 auto 0;
            width: 90%;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                width: 100%;
            }

            .item {
                margin-bottom: 50px;
                position: relative;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(md) {
                    margin-bottom: 0;
                    margin-top: 50px;
                }

                h2 {
                    margin: 0;
                }

                label {
                    color: white;
                    display: block;
                    text-transform: uppercase;
                    font-size: 20px;
                    font-weight: 600;
                    margin-top: 0;
                    width: 100%;
                    transition: all 0.5s ease-out;

                    &.big {
                        margin-top: 0;
                        font-size: 40px;

                        strong {
                            color: #38AADC;
                        }
                    }

                    &.blue {
                        color: #38AADC;
                        margin: 0;

                        @include media-breakpoint-up(md) {
                            margin-top: 40px;
                        }
                        @include media-breakpoint-up(lg) {
                            margin-top: 120px;
                        }
                    }

                    @include media-breakpoint-up(md) {
                        margin-top: 18px;
                        font-size: 17px;
                    }
                }

                p {
                    color: white;
                    display: block;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;

                    @include media-breakpoint-up(lg) {
                        width: 300px;
                    }
                }

                .wrap-video {
                    position: relative;
                    height: 220px;
                    max-width: 813px;
                    width: 100%;

                    @include media-breakpoint-up(md) {
                        height: 350px;
                        margin: 0 auto;
                        width: 92%;
                    }
                    @include media-breakpoint-up(lg) {
                        height: 480px;
                    }

                    .poster {
                        background-image: url("/images/poster-video1.jpg");
                        background-position: center center;
                        background-size: cover;
                        position: absolute;
                        display: block;
                        height: 100%;
                        width: 100%;
                        z-index: 1;
                    }
                    iframe {
                        position: relative;
                        height: 100%;
                        width: 100%;
                        z-index: 0;
                    }
                }
            }
        }
    }

    .wrap-subscribe-2 {
        background-image: url("/images/destello.png");
        background-size: 200% auto;
        background-position: center 90%;
        background-repeat: no-repeat;
        padding-bottom: 50px;
        padding-top: 10px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(lg) {
            background-size: 135% auto;
            background-position: center 90%;
            padding-top: 60px;
        }
        @include media-breakpoint-up(xl) {
            background-size: 110% auto;
        }

        @media (min-width: 1440px) {
            background-size: 1300px auto;
        }

        .wrap-content {
            margin: 0 auto 120px;
            width: 75%;
            transition: all 0.5s ease-out;

            label, h2 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(lg) {
                    display: block;
                    text-align: center;
                    margin: 0 auto;
                    width: 550px;
                }


                &.title {
                    color: #FF0178;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    text-transform: uppercase;

                    a {
                        color: #FF0178;
                    }

                    @include media-breakpoint-up(lg) {
                        display: block;
                        text-align: center;
                        width: 100%;
                    }
                }
            }
        }

        .wrap-items {
            position: relative;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                display: flex;
                justify-content: space-between;
                margin: 0 auto;
                width: 90%;
            }

            @include media-breakpoint-up(lg) {
                width: 880px;
            }

            .item {
                position: relative;
                padding-bottom: 85px;
                padding-top: 85px;
                width: 100%;
                transition: all 0.5s ease-out;

                &.ilimitada {
                    background: linear-gradient(180deg, #0E9ED4 0%, rgba(14, 158, 212, 0.00) 100%);

                    img {
                        width: 120px;
                    }
                }

                &.dispositivos {
                    background: linear-gradient(180deg, #E41C7A 13.02%, rgba(228, 28, 122, 0.00) 100%);

                    @include media-breakpoint-up(md) {
                        margin-top: -32px;
                    }

                    img {
                        width: 98px;
                    }
                }

                &.original {
                    background: linear-gradient(180deg, #4545FA 0%, rgba(69, 69, 250, 0.00) 100%);

                    img {
                        width: 100px;
                    }
                }

                img {
                    transition: all 0.5s ease-out;
                    margin: 0 auto 20px;
                    display: block;
                }

                .title {
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    text-transform: uppercase;
                    display: block;
                    margin: 0 auto 15px;
                    width: 200px;
                    transition: all 0.5s ease-out;
                }

                p {
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    text-transform: uppercase;
                    display: block;
                    margin: 0 auto 20px;
                    width: 200px;
                    transition: all 0.5s ease-out;
                }

            }
        }

        .wrap-store {
            .Btn {
                background-image: linear-gradient(to top, #d02e7c, #d02e7c);
                color: #fff;
                display: block;
                padding: 13px 37px 13px 41px;
                border-radius: 20px;
                font-size: 16px;
                font-weight: 400;
                line-height: normal;
                text-align: center;
                margin: 0 auto 40px;
                width: 187px;
                height: 42px;
                text-transform: uppercase;
                transition: all 0.5s ease-out;
            }

            .wrap-app-store {
                transition: all 0.5s ease-out;
                margin: 0 auto;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 300px;
            }
        }

    }

    .wrap-subscribe-3 {
        background: linear-gradient(0deg, #E41C7A 0%, rgba(228, 28, 122, 0) 20%);
        padding-bottom: 90px;
        padding-top: 90px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            padding-bottom: 150px;
            padding-top: 150px;
        }

        .wrap-content {
            margin: 0 auto 20px;
            width: 75%;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(xl) {
                width: 800px;
            }
            @media (min-width: 1400px) {
                width: 1200px;
            }

            label, h2 {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                transition: all 0.5s ease-out;

                @include media-breakpoint-up(lg) {
                    width: 410px;
                }
                @media (min-width: 1400px) {
                    margin-right: 200px;
                }

                &.title {
                    color: white;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    text-transform: uppercase;

                    @include media-breakpoint-up(lg) {
                        width: 620px;
                    }

                    span {
                        color: #00B1E2;
                    }
                }
            }
        }

        .wrap-items {
            z-index: 1;
            position: relative;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                margin: 0 auto;
                width: 75%;
            }
            @include media-breakpoint-up(xl) {
                width: 800px;
            }

            @media (min-width: 1400px) {
                width: 1200px;
            }

            .item {
                position: relative;
                padding-bottom: 20px;
                width: 100%;
                transition: all 0.5s ease-out;

                .Btn {
                    background-image: linear-gradient(to top, #d02e7c, #d02e7c);
                    color: #fff;
                    display: block;
                    padding: 13px 37px 13px 41px;
                    border-radius: 20px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: normal;
                    text-align: center;
                    margin: 0 auto 40px;
                    width: 280px;
                    height: 42px;
                    text-transform: uppercase;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(lg) {
                        margin-left: 0;
                        margin-bottom: 40px;
                    }
                }

                .wrap-app-store {
                    margin: 0 auto;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 300px;
                    transition: all 0.5s ease-out;

                    @include media-breakpoint-up(lg) {
                        margin: 0;
                        width: 300px;
                    }
                }
            }

        }
    }
}

.wrap-account {
    margin-bottom: 60px;
    transition: all 0.5s ease-out;

    .account-content {
        background-color: #E41C7A;

        .wrap-img-list {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 auto;
            width: 90%;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                justify-content: center;
                width: 795px;
            }
            @include media-breakpoint-up(xl) {
                position: relative;
                top: -60px;
            }
            @media (min-width: 1400px) {
                top: -160px;
            }
            @media (min-width: 1600px) {
                top: -160px;
                width: 900px;
            }
        }

    }
    .account-content-down {
        background: linear-gradient(to bottom, #E41C7A 0%,  rgba(228, 28, 122, 0.00) 100%);

        .wrap-text {
            padding-top: 90px;
            margin: 0 auto 0;
            width: 90%;

            @include media-breakpoint-up(xl) {
                padding-top: 40px;
            }
            @media (min-width: 1400px) {
                padding-top: 0;
            }

            label {
                color: #FFF;
                text-align: center;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;

                @include media-breakpoint-up(lg) {
                    display: block;
                    margin: 0 auto;
                    width: 555px;
                }

                &.title {
                    text-align: center;
                    font-size: 40px;
                    font-style: normal;
                    font-weight: 800;
                    line-height: normal;
                    text-transform: uppercase;
                    display: block;
                    text-align: center;
                }
            }
        }

        .wrap-video {
            display: block;
            margin: 50px auto 0;
            height: 230px;
            position: relative;
            max-width: 980px;
            width: 95%;

            @include media-breakpoint-up(md) {
                height: 350px;
            }
            @include media-breakpoint-up(lg) {
                height: 480px;
            }

            .poster {
                background-image: url("/images/poster-video2.jpg");
                background-position: center center;
                background-size: cover;
                cursor: pointer;
                position: absolute;
                display: block;
                height: 100%;
                width: 100%;
                z-index: 1;
            }
            iframe {
                position: relative;
                height: 100%;
                width: 100%;
                z-index: 0;
            }
        }

    }



    h2 {
        color: #d02e7c;
        font-size: 26px;
        font-weight: 600;
        display: block;
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 2px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            font-size: 28px;
        }

        span {
            color: white;
        }
    }

    .Btn {
        background-image: linear-gradient(to bottom, white, white);
        color: #d02e7c;
        border-radius: 10px;
        height: 44px;
        margin: 20px auto 0;
        text-transform: uppercase;
        display: block;
        letter-spacing: 2px;
        font-size: 16px;
        font-weight: 600;
        width: 300px;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            height: 52px;
            font-size: 20px;
            line-height: 26px;
            width: 350px;
        }

        &.clear {
            background-image: linear-gradient(to bottom, transparent, transparent);
            color: white;
            opacity: 0.7;
            height: auto;
            font-weight: 200;
            margin-top: 0;
            font-size: 11px;

            @include media-breakpoint-up(md) {
                font-size: 16px;
            }
        }
    }
}

.wrap-movies {
    background-image: url("/images/inzpira-background.png");
    background-size: 150% auto;
    background-position: center 78%;
    background-repeat: no-repeat;
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(md) {
        background-size: 100% auto;
        background-position: center 70%;
        margin-top: 90px;
    }

    @media (min-width: 1400px) {
        background-position: center 100%;
    }

    h2 {
        color: #FF0178;
        font-size: 40px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        text-transform: uppercase;
        display: block;
        text-align: center;
        transition: all 0.5s ease-out;
    }


    .wrap-titles {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 38px auto 38px;
        width: 90%;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(md) {
            width: 450px;
        }

        label, h3 {
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            text-transform: uppercase;
            transition: all 0.5s ease-out;

            &.active {
                color: #00B1E2;
            }

            @include media-breakpoint-up(md) {
                &:hover {
                    color: #00B1E2;
                }
            }
        }
    }

    .wrap-slider {
        height: 0;
        opacity: 0;
        position: relative;
        z-index: 0;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(xl) {
            margin: 0 auto;
            width: 1000px;
        }

        @media (min-width: 1440px) {
            width: 1200px;
        }

        &.active {
            height: auto;
            opacity: 1;
            z-index: 1;
        }

        .slick-slide {
            transition: all 0.5s ease-out;
            img {
                padding-left: 15px;
                padding-right: 15px;

                @include media-breakpoint-up(md) {
                    padding-left: 5px;
                    padding-right: 5px;
                }
            }
        }
    }

    .slick-dots {
        transition: all 0.5s ease-out;
        li {
            &.slick-active {
                button {
                    &:before {
                        color: #FF0178;
                        opacity: 1;
                    }
                }
            }
            button{
                &:before {
                    color: white;
                    font-size: 12px;
                    opacity: 1;
                }
            }
        }
    }

    .main-wrap-device {
        background: linear-gradient(180deg, rgba(14, 158, 212, 0.00) 54.17%, #E41C7A 100%);
        transition: all 0.5s ease-out;

        .wrap-device {
            padding-top: 110px;
            margin: 0 auto;
            width: 300px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                width: 550px;
            }

            h2 {
                color: #FFF;
                font-size: 40px;
                font-style: normal;
                font-weight: 800;
                line-height: normal;
                text-transform: uppercase;
                text-align: center;
                transition: all 0.5s ease-out;

                span {
                    color: #00B1E2;
                }
            }
            label {
                color: #FFF;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                text-transform: uppercase;
                transition: all 0.5s ease-out;
                display: block;
                text-align: center;
            }

            img {
                margin-top: 80px;
                transition: all 0.5s ease-out;
            }
        }
    }

}

.wrap-start {
    background: linear-gradient(180deg, #E41C7A 2.6%, #E41C7A 100%);
    padding-top: 80px;
    position: relative;
    transition: all 0.5s ease-out;

    @include media-breakpoint-up(lg) {
        padding-top: 80px;
    }
    @include media-breakpoint-up(xl) {
        padding-top: 0;
    }

    .main-wrap-all-devices {
        width: 100%;

        @include media-breakpoint-up(md) {
            padding-left: 5%;
            padding-right: 2%;
        }
        @include media-breakpoint-up(lg) {
            padding-top: 40px;
            margin: 0 auto;
            width: 1024px;
        }
        @include media-breakpoint-up(xl) {
            width: 1200px;
        }

        .wrap-all-devices {
            display: flex;
            position: relative;
            justify-content: center;
            width: 90%;
            margin: 0 auto;
            flex-direction: column;

            @include media-breakpoint-up(md) {
                width: 100%;
                flex-direction: row;
            }

            &.w3 {
                &:last-child {
                    .device {
                        //width: 33.3%;

                        @include media-breakpoint-up(lg) {
                            margin-left: 0;
                            margin-right: 0;
                        }
                    }
                }
            }

            .device {
                padding-left: 6%;
                width: 100%;

                @include media-breakpoint-up(md) {
                    padding-left: 0;
                    width: 33.3%;
                }

                @include media-breakpoint-up(lg) {
                    margin-left: 40px;
                    margin-right: 40px;
                    width: 200px;
                }

                .title {
                    display: block;
                    img {
                        width: 150px;
                    }

                    &.iphone {
                        width: 90px;
                    }
                    &.ipad {
                        width: 60px;
                    }
                    &.android {
                        width: 75px;
                    }
                    &.appletv {
                        width: 50px;
                    }
                    &.roku {
                        width: 65px;
                    }
                    &.androidtv {
                        width: 95px;
                    }
                    &.firetv {
                        width: 70px;
                    }
                }

                a {
                    color: #0D0D50;
                }
            }
        }

    }

    .wrap-text {
        position: relative;
        z-index: 2;
        transition: all 0.5s ease-out;

        @include media-breakpoint-up(xl) {
            position: relative;
            left: 50%;
            top: 50%;
            transform: translate(-50%, 50%);
            width: 400px;
        }

        h2 {
            color: #FFF;
            text-align: center;
            font-size: 40px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-transform: uppercase;
            display: block;
            margin: 0 auto;
            width: 230px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                width: 100%;
            }
        }

        h3 {
            display: block;
            text-align: center;
            color: #FFF;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: uppercase;
            margin: 0 auto;
            width: 290px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(md) {
                width: 450px;
            }
            @include media-breakpoint-up(xl) {
                width: 100%;
            }
        }

        .line {
            background: linear-gradient(270deg, #0D0D50 29.38%, #2121D0 57.63%);
            border-radius: 20px;
            opacity: 0.5;
            height: 3px;
            margin: 30px auto 30px;
            width: 300px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(xl) {
                width: 100%;
            }
        }

        label {
            display: block;
            text-align: center;
        }

        .Btn {
            background: #0D0D52;
            color: white;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            text-transform: uppercase;
            margin: 38px auto 38px;
            text-align: center;
            line-height: 17px;
            height: 44px;
            padding: 15px 37px 15px 41px;
            width: 260px;
            transition: all 0.5s ease-out;
        }

        .wrap-app-store {
            margin: 0 auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 300px;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(xl) {
                width: 100%;
            }
        }
    }

    .triangle {
        margin-top: 100px;
        position: relative;
        transition: all 0.5s ease-out;
        z-index: 1;

        @include media-breakpoint-up(xl) {
            margin-top: 0;
        }

        img {
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            top: -50px;
            width: 200px;
            z-index: 0;
            transition: all 0.5s ease-out;

            @include media-breakpoint-up(lg) {
                left: 5%;
                right: auto;
                top: -250px;
                width: 330px;
            }
        }

        svg {
            position: relative;
            z-index: 1;
            transition: all 0.5s ease-out;
        }
    }

}

.Btn {
    background-image: linear-gradient(to right, #3747e4, #7532e6);
    color: #fff;
    height: 46px;
    display: block;
    padding: 15px 37px 13px 41px;
    border-radius: 19.5px;
    font-size: 18px;
    font-weight: 400;
    line-height: 0.89;
    text-align: center;
    text-decoration: none;
    width: 214px;
    transition: all 0.5s ease-out;
}

.wrap-popUp {
    background-color: rgba(12, 8, 82, 0.6);
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: 4;

    img {
        display: block;
        margin: 0 auto;
        position: relative;
        top: 28%;
        left: 0;
        width: 90%;
        height: auto;
        border-radius: 26px;
        max-width: 1200px;

    }

    .close {
        background-image: url("/images/close.png");
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 60% auto;
        background-color: white;
        position: absolute;
        right: 3%;
        top: 23%;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        display: block;

        @include media-breakpoint-up(md) {
            right: 8%;
            height: 30px;
            width: 30px;
        }
        @include media-breakpoint-up(lg) {
            right: 12%;
        }

    }
}

.aviso-privacidad {
    background-image: none;
    background-color: white;
    color: black;
    padding-top: 100px;

    .back {
        background-image: url("/images/close.png");
        background-repeat: no-repeat;
        background-position: center 0;
        background-size: 30px auto;
        color: #E41C7A;
        display: flex;
        height: 55px;
        position: absolute;
        right: 50px;
        text-align: center;
        justify-content: end;
        width: 46px;
        align-items: flex-end;
    }

    h1 {
        text-align: center;
    }

    strong, label {
        font-weight: 700;
    }
}
