@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700;800&display=swap');

// Variables
//@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~slick-carousel/slick/slick';
@import '~slick-carousel/slick/slick-theme';
@import "theme";
@import "footer";
@import "home";
