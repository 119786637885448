body{
    background-image: linear-gradient(to bottom, #0c0852, #0c0852, #27306e);
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 300;

    &.noScroll{
        main{
            height: 100vh;
            overflow: hidden;
        }
        .hamburger{
            span{
                &:first-child{
                    transform: rotate(45deg) translate(7px, 7px);
                }
                &:nth-child(2){
                    opacity: 0;
                    transform: translateY(-100%);
                }
                &:nth-child(3){
                    transform: rotate(-45deg) translate(6px, -7px);
                }
            }
        }
        .iz-mainMenu,
        .iz-mainMenu ul{
            opacity: 1;
            visibility: visible;
        }
    }
}
