footer {
    background: #0D0D50;
    height: 135px;
    padding-top: 38px;
    position: relative;

    .main {
        img {
            display: block;
            margin: 0 auto;
            width: 238px;
        }
        a {
            color: white;
            text-decoration: underline;
            position: absolute;
            right: 68px;
            font-size: 14px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

}
